// Fonts
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

// Variables
@import 'abstract/variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

// Base
@import 'base/base';

// Partials
@import 'partials/form';

// Licencias
@import 'pages/licenses';

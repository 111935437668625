// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$wine: #6A1C32;
$white: #fff;
$black: #000;
$grey: #434343;
$clear-grey: #E9ECEF;
$whiteGrey: #EFEFEF;
$greyB: #FBF9FA;
$greyBB: #A7B3C3;
$blue: #2277F6;
$blackG: #4A4A4A;
$green: #31CD82;
$blueW: #e5e9ed;
$orange: #ff8f2b;
$redP: #f95151;

// Create text colors and background class
$colors: (
    'white': $white,
    'wine': $wine,
    'black': $black,
    'grey': $grey,
    'clear-grey': $clear-grey,
    'whiteGrey': $whiteGrey,
    'greyB': $greyB,
    'greyBB': $greyBB,
    'blue': $blue,
    'blackG': $blackG,
    'green': $green,
    'blueW': $blueW,
    'orange': $orange,
    'redP': $redP,
);

@each $name, $color in $colors {
    .bg-#{$name}{
        background-color: $color !important;
    }
    .text-#{$name}{
        color: $color !important;
    }
}

html,
body {
    height: 100%;
    font-family: 'Questrial', sans-serif;
}

body {
    background-color: $greyB;
    color: $greyBB;
}

::placeholder {
    color: $greyBB;
}

.title {
    color: $blue;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1.5px;
}

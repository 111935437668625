.form {

    &-login {

        &_box {
            width: 450px;
        }

        &_img {
            width: 200px;
            margin: 0 auto;
        }

        &_input {
            border: none;
            padding: 10px 15px;
            font-size: 18px;
            height: 50px;
            width: 1%;
            flex: 1 1 auto;

            &:focus {
                outline: none;
            }

            &-icon {
                width: 50px;
                height: 50px;
                font-size: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $white;

                svg {
                    width: 20px;
                }
            }

            &-submit {
                background-color: $blue;
                color: $white;
                font-weight: 700;
                font-size: 1.1rem;
                border: none;
                width: 150px;
                height: 50px;
                letter-spacing: 1.5px;
            }
        }

    }
}
